import React, { useEffect, useRef, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa";
import Sidebar from "../Components/Sidebar";
import { useSelector } from "react-redux";
import Pdfpage from "../Components/Pdfpage";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";

function OrderDescription() {
  const navigate = useNavigate();
  const location = useLocation();
  const datas = location.state.data;
  console.log(datas);

  const returnTheQtyCost = (purpose, User) => {
    if (purpose === "Qty") {
      let count = 0;
      User.forEach((element) => {
        count += element.Q;
      });
      return count;
    } else if (purpose === "Cost") {
      let count = 0;
      User.forEach((element) => {
        count += element.P * element.Q;
      });
      return count;
    }
  };
  const sidebarShow = useSelector((e) => e.sidebarStatus.initialValue);
  const User = useSelector((e) => e.testUser.Initialuser);

  useEffect(() => {
    if (User.id == null) {
      navigate("/home");
    }
  }, []);

  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [vendorColorScheme, setVendorColorScheme] = useState(null);

  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            setVendorColorScheme(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  const Options = {
    filename: "Dahlia Craft.pdf",
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.MEDIUM,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };
  const getTargetElement = () => document.getElementById("container");

  const downloadPdf = () => generatePDF(getTargetElement, Options);
  return (
    <div className="w-full flex flex-col relative bg-[#fafafa]">
      <div className="flex flex-col gap-4 bg-white lg:max-w-screen-lg m-auto w-full z-30 p-5">
        <div className="flex flex-row items-center justify-start gap-3 w-full">
          <div
            style={{ backgroundColor: vendorColorScheme?.colorScheme }}
            className="flex flex-col rounded-full p-2 justify-center items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <GoArrowLeft className="w-5 h-5 object-contain text-white" />
          </div>
          <div className="text-black text-lg">Back to all orders</div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-2">
            <div className="lg:text-3xl text-2xl text-left">
              Order {datas.id}
            </div>
            <div className="flex flex-row justify-start gap-2">
              {datas.Payment_status == null ? null : (
                <div
                  className={` text-white ${
                    datas.Payment_status == 1
                      ? "bg-red-600 "
                      : datas.Payment_status == 2
                      ? "bg-green-600 "
                      : "bg-primaryColor"
                  } py-2 h-max rounded-md flex flex-col items-center justify-center font-semibold px-5 text-xs`}
                >
                  <h6 className=" text-sm">
                    {datas.Payment_status == 1
                      ? "On hold"
                      : datas.Payment_status == 2
                      ? "Processing"
                      : "Completed"}
                  </h6>
                </div>
              )}
              {/* <div className=" h-max flex px-5 py-2 rounded-md flex-col items-center justify-center bg-red-400 text-white  text-xs">
                UNPAID
              </div> */}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-black text-xl lg:text-3xl font-bold ">
              ₹ {returnTheQtyCost("Cost", JSON.parse(datas.cart))}
            </div>
            <div className="text-black text-xs lg:text-base">
              {new Date(datas.posteddate).getUTCDate() +
                " " +
                new Date(datas.posteddate).toLocaleDateString("en-US", {
                  month: "long",
                }) +
                " " +
                new Date(datas.posteddate).getUTCFullYear()}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div
            onClick={reactToPrintFn}
            className="bg-white hover:bg-grayColor duration-200 cursor-pointer flex border flex-row gap-2 items-center justify-center py-3 px-5 rounded-md shadow"
          >
            <FaRegFilePdf className="w-5 h-5 text-[#dc6969]" />
            <h4 className="text-black text-base font-semibold">Download PDF</h4>
          </div>
        </div>
        <div className="flex flex-col gap-2 bg-white border rounded-lg p-5 shadow">
          <div className="text-lg font-bold text-left">Billing Details</div>
          <div className="text-base  text-black">
            {JSON.parse(datas.address).N}
          </div>
          <div className="text-base  text-black">
            {JSON.parse(datas.address).email}
          </div>
          <div className="text-base  text-black">{User.mobileNumber}</div>
        </div>
        <div className="flex flex-col gap-2 bg-white border rounded-lg p-5 shadow">
          <div className="text-lg font-bold text-left">Additional Details</div>
          <div className="text-base  text-black">
            Customer Name :{" "}
            {JSON.parse(User.delivery_address)[datas.delivery_address].CusName}
          </div>
          <div className="text-base  text-black">
            Mobile Number :{" "}
            {
              JSON.parse(User.delivery_address)[datas.delivery_address]
                .CusMobile
            }
          </div>
          <div className="text-base  text-black">
            Address :{" "}
            {JSON.parse(User.delivery_address)[datas.delivery_address].Ad1}{" "}
            {JSON.parse(User.delivery_address)[datas.delivery_address].Ad2}
          </div>
          <div className="text-base  text-black ">
            City :{" "}
            {JSON.parse(User.delivery_address)[datas.delivery_address].CusCity},{" "}
            {JSON.parse(User.delivery_address)[datas.delivery_address].CusState}
          </div>
          <div className="text-base  text-black ">
            Pincode :{" "}
            {JSON.parse(User.delivery_address)[datas.delivery_address].pincode}
          </div>
        </div>
        <div className="flex flex-col gap-2 bg-white border rounded-lg p-5 shadow">
          <div className="text-xl  text-black font-semibold  w-full self-center">
            Your order items
          </div>
          <div className="w-full">
            {JSON.parse(datas.cart).map((item, index) => (
              <div
                key={index}
                className="w-full h-[100px] flex flex-col justify-center items-center border-b"
              >
                <div className="flex flex-row justify-between items-center w-full">
                  <div className="flex flex-row items-center gap-2">
                    <img
                      src={
                        "https://storage.googleapis.com/cloudsb/ProductImage/" +
                        item.T
                      }
                      className="w-[75px] h-[75px] rounded-md"
                    />
                    <div className="flex flex-col gap-2">
                      <div className="text-base text-H1TextColor font-bold">
                        {item.N}
                      </div>
                      <div className="text-sm text-black">Qty: {item.Q}</div>
                    </div>
                  </div>
                  <div
                    style={{ color: vendorColorScheme?.colorScheme }}
                    className="text-xl font-semibold"
                  >
                    ₹ {item.P * item.Q}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between gap-2 bg-white border rounded-lg p-5 shadow">
          <div className="text-black text-2xl font-bold">Total</div>
          <div className="text-black text-2xl font-bold">
            ₹{returnTheQtyCost("Cost", JSON.parse(datas.cart))}
          </div>
        </div>
      </div>
      <div className="absolute z-0 lg:w-[60%] lg:max-w-full self-center w-full mx-auto top-10 lg:p-10 ">
        <Pdfpage
          id="container"
          data={datas}
          returnTheQtyCost={returnTheQtyCost}
          reference={contentRef}
        />
      </div>
      {sidebarShow == 1 ? <Sidebar /> : null}
    </div>
  );
}
export default OrderDescription;

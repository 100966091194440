import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Components/Sidebar";
import FloatingFooter from "../Components/FloatingFooter.js";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { TestUserFunction } from "../Components/Redux/TestuserSlice.js";
import {
  onAddToCart,
  QuantityClicked,
} from "../Components/ReusableFunction.js";
import { UpdateTheData } from "../APi/ApiCollection.js";
const Products = () => {
  const location = useLocation();
  const data = location.state.CategoryID;
  const userId = location.state.userId;
  const vendordata = location.state.vendordata;
  const Name = location.state.CategoryName;
  const User = useSelector((e) => e.testUser.Initialuser);
  const more = useSelector((e) => e.categorydata.initialCategory);

  const [moreCategory, setMoreCategory] = useState([]);
  useEffect(() => {
    if (data) {
      if (more != null) {
        setMoreCategory(more.filter((e) => e.id !== data));
      }
    }
  }, [data]);

  useEffect(() => {
    if (more == null) {
      navigate("/home");
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (data != undefined) FetchingProductData(data);
  }, [data]);
  const navigate = useNavigate();

  const [productData, setProductData] = useState([]);
  async function FetchingProductData(_id) {
    try {
      axios
        .get(
          `https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProducts/categoryAndVendorSpecific/${userId}|${_id}?limit=100&offset=0`
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              let store = [];
              let temp = [...response.data];
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].stock_status == 1) {
                  if (
                    new Date() >= new Date(temp[i].saleApplicableFrom) &&
                    new Date() <= new Date(temp[i].saleApplicableTo)
                  ) {
                    store.push(temp[i]);
                  } else if (
                    temp[i].saleApplicableFrom == null ||
                    temp[i].saleApplicableTo == null
                  ) {
                    store.push(temp[i]);
                  }
                }
              }
              setProductData(store);
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  const onAddToCartClicked = async (_obj) => {
    const temp = onAddToCart(User, _obj, userId);
    const data1 = {
      cart: JSON.stringify(temp),
    };
    try {
      const response = await UpdateTheData(User.id, data1);
      if (response.status == 200) {
        dispatch(
          TestUserFunction({ type: "cart", value: JSON.stringify(temp) })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const QuantityManagement = async (_obj, type) => {
    const temp = QuantityClicked(User, _obj, type);
    const data1 = {
      cart: temp.length == 0 ? null : JSON.stringify(temp),
    };
    try {
      const response = await UpdateTheData(User.id, data1);
      if (response.status == 200) {
        dispatch(
          TestUserFunction({
            type: "cart",
            value: temp.length == 0 ? null : JSON.stringify(temp),
          })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sidebarShow = useSelector((e) => e.sidebarStatus.initialValue);
  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [vendorColorScheme, setVendorColorScheme] = useState(null);

  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            setVendorColorScheme(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className=" w-full flex flex-col relative">
      <div className="max-w-[1600px] w-full flex flex-col gap-5 p-5 mx-auto">
        <div className="heading text-left text-xl lg:text-2xl font-bold md:font-semibold">
          {Name}
        </div>
        <div className="gridHolderProduct gap-5 w-full">
          {productData.length > 0 ? (
            productData.map((item, index) => (
              <div
                key={index}
                className="w-full bg-white shadow-md rounded-xl  justify-between cursor-pointer flex flex-col items-center"
              >
                <img
                  onClick={() =>
                    navigate("/productDescription", {
                      state: {
                        vendordata: vendordata,
                        userid: userId,
                        productArray: productData,
                        indexValue: index,
                      },
                    })
                  }
                  className="imageHeights"
                  src={
                    "https://storage.googleapis.com/cloudsb/ProductImage/" +
                    item.thumbnail
                  }
                />
                <div
                  className="text-H1TextColor font-bold text-sm self-start mx-2 "
                  onClick={() =>
                    navigate("/productDescription", {
                      state: {
                        vendordata: vendordata,
                        userid: userId,
                        productArray: productData,
                        indexValue: index,
                      },
                    })
                  }
                >
                  {item.product_name}
                </div>

                {vendordata.showQuantity != null &&
                vendordata.showQuantity == 1 ? (
                  <span
                    className={`${
                      item.product_Quantity == 0
                        ? "text-red-600"
                        : "text-green-600"
                    } self-start mx-2  opacity-80 font-semibold text-sm`}
                  >
                    {" "}
                    {item.product_Quantity == 0
                      ? "Out of Stock"
                      : item.product_Quantity + " Products Available"}
                  </span>
                ) : null}
                <div
                  className="text- font-semibold self-start mx-2"
                  onClick={() =>
                    navigate("/productDescription", {
                      state: {
                        vendordata: vendordata,
                        userid: userId,
                        productArray: productData,
                        indexValue: index,
                      },
                    })
                  }
                >
                  {item.sale_prize == 0 ? (
                    <span>₹{item.regular_prize}</span>
                  ) : (
                    <div className="flex flex-row items-center space-x-3">
                      <span className="line-through text-red-600">
                        ₹{item.regular_prize}
                      </span>
                      <span>₹{item.sale_prize}</span>
                    </div>
                  )}

                  {/* ₹{item.sale_prize==0?item.regular_prize:item.sale_prize} */}
                </div>
                {User.cart == null ? (
                  <div
                    style={{
                      backgroundColor: vendorColorScheme?.colorScheme,
                    }}
                    className="shortContent text-center py-3 w-full rounded-b-lg font-semibold text-white  duration-200 hover:bg-HoverColor"
                    onClick={() => onAddToCartClicked(item)}
                  >
                    ADD TO CART
                  </div>
                ) : JSON.parse(User.cart).findIndex(
                    (e) => e.I == item.product_id
                  ) == -1 ? (
                  <div
                    style={{
                      backgroundColor: vendorColorScheme?.colorScheme,
                    }}
                    className="shortContent text-center py-3 w-full rounded-b-lg font-semibold text-white  duration-200 hover:bg-HoverColor"
                    onClick={() => onAddToCartClicked(item)}
                  >
                    ADD TO CART
                  </div>
                ) : (
                  <div
                    style={{ borderColor: vendorColorScheme?.colorScheme }}
                    className="rowHolder w-full  justify-between items-center border  rounded-b-lg"
                  >
                    <div
                      className="shortContent text-center py-3 w-[30%]  rounded-bl-lg font-semibold text-black bg-[#D3D3D3]"
                      onClick={() => QuantityManagement(item, "Dec")}
                    >
                      -
                    </div>
                    <div className="heading  font-normal  w-[40%]">
                      {
                        JSON.parse(User.cart).find(
                          (e) => e.I == item.product_id
                        ).Q
                      }
                    </div>
                    <div
                      style={{
                        backgroundColor: vendorColorScheme?.colorScheme,
                      }}
                      className="shortContent text-center py-3 w-[30%]   rounded-br-lg font-semibold text-white  "
                      onClick={() => QuantityManagement(item, "Inc")}
                    >
                      +
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <h3 className="font-semibold text-sm opacity-80">
              No Products Found
            </h3>
          )}
        </div>
        {more != null && moreCategory.length > 0 ? (
          <>
            <div className="flex flex-row items-center justify-between">
              <div className="heading text-base lg:text-lg font-bold text-left">
                More Catalogues
              </div>
              <div
                style={{ color: vendorColorScheme?.colorScheme }}
                className="heading text-base lg:text-lg  font-bold cursor-pointer"
                onClick={() => navigate("/home")}
              >
                View all catalogues
              </div>
            </div>
            <div className="rowHolder gap-3 justify-start w-full scrollbar overflow-x-auto">
              {moreCategory.map((item, index) =>
                item.status == 1 ? (
                  <div
                    className="flex flex-col mb-2 w-[190px] flex-none"
                    key={index}
                  >
                    <div
                      className="catalogueSubParent cursor-pointer"
                      onClick={() =>
                        navigate("/products", {
                          state: {
                            vendordata: vendordata,
                            userId: userId,
                            CategoryID: item.id,
                            CategoryName: item.cat_name,
                          },
                        })
                      }
                    >
                      <img
                        className="w-full min-[325px]:h-[200px] min-[426px]:h-[230px] object-fill rounded-t-lg"
                        src={
                          "https://storage.googleapis.com/cloudsb/product_category/" +
                          item.thumbnail
                        }
                      />
                      <div className="heading font-bold text-sm">
                        {item.cat_name}
                      </div>
                      <div className="lg:shortContent text-[13px] mb-1 text-center">
                        Private Catalogue
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </>
        ) : null}
      </div>
      {sidebarShow == 1 ? <Sidebar /> : null}
      {User.cart != null &&
      JSON.parse(User.cart).findIndex((e) => e.V == userId) != -1 ? (
        <FloatingFooter />
      ) : null}
    </div>
  );
};
export default Products;

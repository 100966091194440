import axios from "axios";

export async function UpdateTheData(Number, data) {
  try {
    const responce = await axios.patch(
      `https://cbdconsole-6zwsgvs5iq-el.a.run.app/users/individual/${Number}`,
      data
    );
    return responce;
  } catch (error) {
    console.log(error);
  }
}

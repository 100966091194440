import React, { useState, useRef, useEffect } from "react";
import RightArrowIcon from "../Assets/RightArrowIcon.png";
import axios from "axios";
import Dahlialogo from "../Assets/Dahlialogo.png";
import { UpdateTheData } from "../APi/ApiCollection";
import { PatchingCategoryData } from "../Components/Redux/CategorySlice";
import { useDispatch } from "react-redux";
import { PatchingProducts } from "../Components/Redux/ProductsSlice";
import { useNavigate } from "react-router-dom";
import { TestUserFunction } from "../Components/Redux/TestuserSlice";

function AuthenticationPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showActiveIndicaotor, setShowActiveIndicaotor] = useState(false);
  const navigate = useNavigate();
  const [VendorData, setVendorData] = useState({});

  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    console.log(domainname);
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            setVendorData(response.data[0]);
            FetchingProducts(response.data[0].id);
            FetchingCategory(response.data[0].id);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    let result = localStorage.getItem("mobileNumber");
    if (result != null) {
      if (result.length > 0) {
        onGetOTPClicked(result);
      }
    }
  }, []);

  const onGetOTPClicked = async (mobileNumber) => {
    setShowActiveIndicaotor(true);
    if (mobileNumber.length == 13) {
      if (isNaN(mobileNumber)) {
        setErrorMessage("Enter a valid mobile number");
      } else {
        let num =
          VendorData.id == undefined
            ? localStorage.getItem("vendorID")
            : VendorData.id;
        try {
          await axios
            .get(
              "https://cbdconsole-6zwsgvs5iq-el.a.run.app/getUsers/individual/" +
                mobileNumber +
                "|" +
                num
            )
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                if (response.data.length == 0) {
                  PostingTheUser(mobileNumber, num);
                } else {
                  if (response.data[0].status == "VERIFIED") {
                    UpdateTheData(response.data[0].id, {
                      updatedDate:
                        new Date().getUTCFullYear() +
                        "-" +
                        (new Date().getUTCMonth() + 1) +
                        "-" +
                        new Date().getUTCDate() +
                        " " +
                        new Date().getUTCHours() +
                        ":" +
                        new Date().getUTCMinutes() +
                        ":" +
                        new Date().getUTCSeconds(),
                    });
                    console.log("Update");
                    localStorage.setItem("mobileNumber", mobileNumber);
                    localStorage.setItem("vendorID", num);
                    PatchSpecificUser(mobileNumber);
                    setShowActiveIndicaotor(false);
                    setErrorMessage("");
                    navigate("/home");
                  } else {
                    alert("Still The Vendor was Not Appvoved You");
                  }
                }
              }
            });
        } catch (error) {
          console.log(error.message);
        }
      }
    } else {
      setErrorMessage("Enter a valid mobile number");
    }
  };
  async function PatchSpecificUser(number) {
    try {
      axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/getUsers/individual/" +
            number +
            "|" +
            VendorData.id
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              dispatch(
                TestUserFunction({ type: "id", value: response.data[0].id })
              );
              dispatch(
                TestUserFunction({ type: "name", value: response.data[0].name })
              );
              dispatch(
                TestUserFunction({
                  type: "mobileNumber",
                  value: response.data[0].mobileNumber,
                })
              );
              dispatch(
                TestUserFunction({
                  type: "address",
                  value: response.data[0].address,
                })
              );
              dispatch(
                TestUserFunction({
                  type: "delivery_address",
                  value: response.data[0].delivery_address,
                })
              );
              dispatch(
                TestUserFunction({ type: "cart", value: response.data[0].cart })
              );
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  const dispatch = useDispatch();
  async function FetchingProducts(_id) {
    try {
      await axios
        .get(
          `https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProducts/vendorSpecific/${_id}?limit=200&offset=0`
        )
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.length > 0) {
              let store = [];
              let temp = [...response.data];
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].stock_status == 1) {
                  if (
                    new Date() >= new Date(temp[i].saleApplicableFrom) &&
                    new Date() <= new Date(temp[i].saleApplicableTo)
                  ) {
                    store.push(temp[i]);
                  } else if (
                    temp[i].saleApplicableFrom == null ||
                    temp[i].saleApplicableTo == null
                  ) {
                    store.push(temp[i]);
                  }
                }
              }
              dispatch(PatchingProducts(store));
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  async function FetchingCategory(_id) {
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProductCategory/vendorSpecific/" +
            _id
        )
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.length > 0) {
              dispatch(
                PatchingCategoryData(response.data.filter((e) => e.status == 1))
              );
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  async function PostingTheUser(mobile, num) {
    const data = {
      name: "",
      mobileNumber: "+91" + mobileNumber,
      address: "",
      creationDate:
        new Date().getUTCFullYear() +
        "-" +
        (new Date().getUTCMonth() + 1) +
        "-" +
        new Date().getUTCDate() +
        " " +
        new Date().getUTCHours() +
        ":" +
        new Date().getUTCMinutes() +
        ":" +
        new Date().getUTCSeconds(),
      updatedDate:
        new Date().getUTCFullYear() +
        "-" +
        (new Date().getUTCMonth() + 1) +
        "-" +
        new Date().getUTCDate() +
        " " +
        new Date().getUTCHours() +
        ":" +
        new Date().getUTCMinutes() +
        ":" +
        new Date().getUTCSeconds(),
      status: "VERIFIED",
      vendorId: VendorData?.id,
    };
    try {
      axios
        .post("https://cbdconsole-6zwsgvs5iq-el.a.run.app/PostUser", data)
        .then((response) => {
          console.log(response);
          // alert('Your Request was Sended Successfully, Wait For Vendor Approvel')
          localStorage.setItem("mobileNumber", mobile);
          localStorage.setItem("vendorID", num);
          navigate("/home");
          console.log("User done");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }
  // const a = [10, 20, 30];
  // const b = a;
  // b.push(40);
  // console.log(a);
  // console.log(b);
  // let a = 4;
  // let b = new Number(4);
  // console.log(a == b);
  // console.log(a === b);
  // let a = 5;
  // a = a + 10;
  // console.log(a);
  // let num1 = 10;
  // let num2 = num1;
  // num2 = 20;
  // console.log(num1);
  // console.log(num2);
  const word = "Codeforces";
  console.log(word.toUpperCase());

  // const [UsersData, setUsersData] = useState([])
  // async function FetchingUsers(_id) {
  //   try {
  //     await axios.get(`https://cbdconsole-6zwsgvs5iq-el.a.run.app/getUsers/vendorSpecificAll/${_id}?limit=10&offset=0`)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           setUsersData(response.data)
  //         }
  //       }).catch((error) => {
  //         console.log(error.message);
  //       })
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }
  // const onEnteredOTPClicked = async () => {
  //   await user.confirm(otp).then((result) => {
  //     setUser(null);
  //     console.log(result);
  //     <Home />
  //     // navigate('/')
  //   }).catch((error) => {
  //     console.log(error.message);
  //   });
  // };
  console.log(VendorData?.colorScheme);
  // [#F5F5F5]
  return (
    <div className="w-full flex flex-col items-center justify-center bg-[#fafafa]  h-screen">
      {/* {otpReceived === false ? ( */}
      <div className="flex flex-col gap-y-2 bg-[#F5F5F5] shadow border rounded-lg  max-w-lg lg:w-full w-[90%] mx-auto p-5 relative justify-center">
        <img
          src={
            VendorData?.businessLogoFile == null
              ? Dahlialogo
              : `https://storage.googleapis.com/cloudsb/vendors/${VendorData?.businessLogoFile}`
          }
          className="lg:h-[50px] object-contain h-[35px]"
        />
        <div
          className="flex flex-row justify-between bg-white items-center space-x-2 p-2 rounded-lg"
          style={{
            border:
              errorMessage.length > 0 ? "1px solid red" : "1px solid black",
          }}
        >
          <div className="heading text-base">+91</div>
          <input
            maxlength="10"
            type="number"
            value={mobileNumber}
            placeholder="Type Phone"
            className="outline-0 font-semibold heading text-base text-left w-full"
            onChange={(e) => {
              if (e.target.value.length <= 10) setMobileNumber(e.target.value);
              if (isNaN(e.target.value) || e.target.value.length > 10) {
              } else {
                setErrorMessage("");
              }
            }}
          />
        </div>
        <div className="text-sm text-red-500">{errorMessage}</div>
        {showActiveIndicaotor == false ? (
          <div className="flex flex-row justify-between w-full items-center">
            <div
              style={{ backgroundColor: VendorData?.colorScheme }}
              className="flex flex-row w-full rounded-md cursor-pointer justify-center items-center space-x-4 p-2"
              onClick={() => onGetOTPClicked("+91" + mobileNumber)}
            >
              <div className="font-semibold text-base text-white">Login</div>
              <img className="w-5 h-5 object-contain" src={RightArrowIcon} />
            </div>
          </div>
        ) : null}
      </div>

      {/* ) : (
        <div className="columnHolder bg-[#F5F5F5] rounded-lg md:w-[95%] sm:w-[95%] 2xl:w-[35%] xl:w-[35%] lg:w-[35%] w-[95%] self-center gap-y-[10px] py-[15px] relative">
          <div className="w-[50px] h-[50px] rounded-full bg-ButtonColor self-center justify-center items-center flex flex-col -top-[30px] absolute border-4 border-white">
            <img className="w-10 h-10" src={Dahlialogo} />
          </div>
          <div className="heading">Dahlia Craft</div>
          <div className="shortContent text-center">
            Enter the OTP sent on +91{mobileNumber}
          </div>
          <div className="rowHolder  items-center justify-around h-[100px]">
            <input onChange={(e) => setOtp(e.target.value)} className="p-2 w-[60%] border-ButtonColor rounded-md" />
          </div>

          <div className="rowHolder w-full justify-center items-center">
            {/* <div className="shortContent">Change Number</div> */}
      {/* <div
              className="rowHolder bg-ButtonColor w-[60%] rounded-md p-2 cursor-pointer justify-center items-center space-x-4 "
              onClick={() => {
                otp.length !== 0 && user != null ? onEnteredOTPClicked() : alert('Please Enter The Correct OTP')
              }}
            >
              <div className="heading text-white">Continue</div>
              <img className="squareIcons" src={RightArrowIcon} />
            </div>
          </div>
        </div> */}
      {/* )} */}
    </div>
  );
}
export default AuthenticationPage;
{
  /* <input
              ref={firstLetterRef}
              maxLength={1}
              type="text"
              className="otpInput"
              onChange={(e) => {
                firstRef.current = e.target.value;
                secondLetterRef.current.focus();
              }}
              value={firstRef.current}
            />
            <input
              ref={secondLetterRef}
              maxLength={1}
              type="text"
              className="otpInput"
              onChange={(e) => {
                secondRef.current = e.target.value;
                thirdLetterRef.current.focus();
              }}
              value={secondRef.current}
            />
            <input
              ref={thirdLetterRef}
              maxLength={1}
              type="text"
              className="otpInput"
              onChange={(e) => {
                thirdRef.current = e.target.value;
                fourthLetterRef.current.focus();
              }}
              value={thirdRef.current}
            />
            <input
              ref={fourthLetterRef}
              maxLength={1}
              type="text"
              className="otpInput"
              onChange={(e) => {
                fourthRef.current = e.target.value;
                fifthLetterRef.current.focus();
              }}
              value={fourthRef.current}
            />
            <input
              ref={fifthLetterRef}
              maxLength={1}
              type="text"
              className="otpInput"
              onChange={(e) => {
                fifthRef.current = e.target.value;
                sixthLetterRef.current.focus();
              }}
              value={fifthRef.current}
            />
            <input
              ref={sixthLetterRef}
              maxLength={1}
              type="text"
              className="otpInput"
              onChange={(e) => {
                sixthRef.current = e.target.value;
              }}
              value={sixthRef.current}
            /> */
}

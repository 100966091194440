import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { IoMdSearch } from "react-icons/io";
import { MdLock } from "react-icons/md";
import { useSelector } from "react-redux";
import axios from "axios";
function SearchPage() {
  const location = useLocation();
  const [searchContent, setSearchContent] = useState("");
  const userId = location.state.userId;
  const vendordata = location.state.vendordata;
  const [searchedProducts, setSearchedProducts] = useState([]);
  const Product = useSelector((e) => e.productdata.ProductsInitial);
  const onSearchContentChanged = (e1) => {
    setSearchContent(e1.target.value);
    let temp = Product.filter((e) =>
      e.product_name
        .toLowerCase()
        .trim()
        .match(e1.target.value.trim().toLowerCase())
    );
    setSearchedProducts(temp);
  };

  const navigate = useNavigate();

  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [vendorColorScheme, setVendorColorScheme] = useState(null);

  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            setVendorColorScheme(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className="mainLayout h-[100vh]  bg-white">
      <div className="rowHolder gap-2 px-3 bg-white justify-between  items-center h-[50px] mb-5 shadow-xl border-2 border-transparent">
        <GoArrowLeft style={{color: vendorColorScheme?.colorScheme}}
          onClick={() => navigate("/home")}
          className="w-7 h-7  cursor-pointer"
        />
        <input
          type="text"
          className="w-full h-[50px] outline-0 shortContent"
          placeholder="Search for products"
          autoFocus
          onChange={(e) => onSearchContentChanged(e)}
          value={searchContent}
        />
      </div>
      {Product == null ? (
        <div className="columnHolder min-h-[90vh] w-full items-center py-[30px]">
          {/* <img className="squareImages" src={SearchIcon} /> */}
          <IoMdSearch className="squareImages" />
          <div className="shortContent text-center">
            Search products by sku,titles,tags,colors,description,prices,etc.
          </div>
        </div>
      ) : (
        <div className="max-w-[1600px] overflow-auto w-full px-5 md:px-10  scrollbar1 mx-auto">
          <div className="gridHolderCatalogue gap-5 relative mb-10">
            {searchedProducts.map((item, index) => (
              <div
                className="catalogueParent relative cursor-pointer shadow-sm rounded-xl"
                key={index}
              >
                <div
                  className="catalogueSubParent pb-2"
                  onClick={() =>
                    navigate("/productDescription", {
                      state: {
                        vendordata: vendordata,
                        userid: userId,
                        productArray: searchedProducts,
                        indexValue: index,
                      },
                    })
                  }
                >
                  <img
                    className="imageHeights"
                    src={
                      "https://storage.googleapis.com/cloudsb/ProductImage/" +
                      item.thumbnail
                    }
                  />
                  <div className="mainContent line-clamp-1 md:mt-2 mt-1 px-2 break-all">
                    {item.product_name}{" "}
                  </div>
                  <div className="shortContent text-center">
                    Private Catalogue
                  </div>
                </div>
                {/* <div className=" rounded-full bg-white md:p-3 p-2 md:top-auto flex flex-col items-center justify-center absolute top-[30%]">
                  <MdLock className="w-6 h-6" />
                </div> */}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default SearchPage;

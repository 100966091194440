import React, { useEffect, useState } from "react";
import { store } from "./Components/Redux/store.js";
import { Provider } from "react-redux";
import RoutingComponent from "./Components/RoutingComponent.js";
import { Helmet } from "react-helmet";
import axios from "axios";
const App = () => {
  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [VendorData, setVendorData] = useState({});
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    console.log(domainname);

    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            setVendorData(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${VendorData.domainName}`}</title>
      </Helmet>
      <RoutingComponent />
    </Provider>
  );
};
export default App;

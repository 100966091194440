import React, { useEffect, useState } from "react";
import CartIcon from "../Assets/CartIcon.png";
import ProfileIcon from "../Assets/ProfileIcon.png";
import SearchIcon from "../Assets/SearchIcon.png";
import HamburgerIcon from "../Assets/HamburgerIcon.png";
import LeftArrowIcon from "../Assets/LeftArrowIcon.png";
import CloseIcon from "../Assets/CloseIcon.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { populate_user } from "./Redux/UserSlice";
import { useCookies } from "react-cookie";
import { ProductsCollections } from "../Collections/Collections";
import { GoArrowLeft } from "react-icons/go";
import { RxHamburgerMenu } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdSearch } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { LiaShoppingBasketSolid } from "react-icons/lia";
import { SidebarFunction } from "./Redux/SidebarSlice";
import axios from "axios";
function Header() {
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies([
    "cart",
    "showStickyHeader",
    "showStickyHeaderCart",
    "showSearchHeader",
    "openSidebar",
  ]);
  const [searchContent, setSearchContent] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const onSearchContentChanged = (e1) => {
    setSearchContent(e1.target.value);
    let temp = ProductsCollections.filter((e) =>
      e.name.toLowerCase().trim().match(e1.target.value.trim().toLowerCase())
    );
    setSearchedProducts(temp);
  };
  const navigate = useNavigate();
  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [VendorData, setVendorData] = useState({});
  const [vendorId, setvendorId] = useState(null);
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            // dispatch(PatchingProducts(response.data))
            // FetchingProducts(response.data[0].id)
            setVendorData(response.data[0]);
            setvendorId(response.data[0].id);
            FetchingCategory(response.data[0].id);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  const User = useSelector((e) => e.testUser.Initialuser);
  const returnTheQtyCost = (purpose) => {
    if (User.cart != null && User != "") {
      if (purpose === "Qty") {
        let count = 0;
        JSON.parse(User.cart).forEach((element) => {
          if (element.V == vendorId) count += element.Q;
        });
        return count;
      } else if (purpose === "Cost") {
        let count = 0;
        JSON.parse(User.cart).forEach((element) => {
          if (element.V == vendorId) count += element.P * element.Q;
        });
        return count;
      }
      // else if (purpose === "Content") {
      //   if (cookies.cart !== undefined) {
      //     return cookies.cart.length;
      //   }
      // }
    }
  };

  const [CategoryData, setCategoryData] = useState([]);
  async function FetchingCategory(_id) {
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProductCategory/vendorSpecific/" +
            _id
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              setCategoryData(response.data.filter((e) => e.status == 1));
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className="columnHolder py-[0%] sticky top-0 z-[100] bg-white">
      {!window.location.href.includes("/productDescription") &&
      !window.location.href.includes("/products") &&
      !window.location.href.includes("/cart") &&
      !window.location.href.includes("/orders") &&
      !window.location.href.includes("/searchPage") &&
      !window.location.href.includes("/orderDescription") &&
      cookies.showStickyHeader == 1 ? (
        <>
          <div className=" bg-white items-center px-5 py-2 lg:hidden rowHolder  ">
            <div className="rowHolder items-center gap-3">
              {/* <img
                src={HamburgerIcon}
                className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCookie("openSidebar", 1);
                }}
              /> */}
              <GiHamburgerMenu
                style={{ color: VendorData?.colorScheme }}
                onClick={() => {
                  dispatch(SidebarFunction(1));
                  window.scrollTo(0, 0);
                }}
                className="font-bold w-5 h-5"
              />
              <div className="columnHolder ">
                <div className="heading ">{VendorData?.businessName}</div>
                <div className="shortContent ">
                  {CategoryData.length} Catalogues
                </div>
              </div>
            </div>
            <div className="rowHolder gap-2">
              {/* <img
                src={SearchIcon}
                className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() =>
                  window.location.href.includes("/products")
                    ? setCookie("showSearchHeader", 1)
                    : navigate("/searchPage")
                }
              /> */}
              <IoMdSearch
                style={{ color: VendorData?.colorScheme }}
                onClick={() =>
                  window.location.href.includes("/products")
                    ? setCookie("showSearchHeader", 1)
                    : navigate("/searchPage")
                }
                className="w-7 h-7 "
              />
              {User.cart !== null ? (
                User.cart.length > 0 ? (
                  <div
                    className="columnHolder  relative"
                    style={{
                      visibility:
                        User.cart !== null
                          ? User.cart.length > 0 && returnTheQtyCost("Qty") != 0
                            ? "visible"
                            : "hidden"
                          : "hidden",
                    }}
                  >
                    <LiaShoppingBasketSolid
                      style={{ color: VendorData?.colorScheme }}
                      className="w-7 h-7 "
                      onClick={() => navigate("/cart")}
                    />

                    <div className="bg-red-600 text-white absolute -bottom-1 -right-2 text-xs w-4 flex flex-col items-center justify-center  h-4 p-1 rounded-full">
                      {returnTheQtyCost("Qty")}
                    </div>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
          <div className="bg-white shadow-xl border-b  z-10 py-2">
            <div className="max-w-[1600px] mx-auto w-full lg:rowHolder hidden">
              <div className="flex flex-col">
                <div className="heading">{VendorData?.businessName}</div>
                <div className="shortContent">
                  {CategoryData.length} Catalogues
                </div>
              </div>

              <div className="flex flex-row items-center gap-3">
                <FaUser
                  style={{ color: VendorData?.colorScheme }}
                  onClick={() => navigate("/orders")}
                  className="w-5 h-5 cursor-pointer"
                />
                <IoMdSearch
                  style={{ color: VendorData?.colorScheme }}
                  className="w-7 cursor-pointer  h-7"
                  onClick={() =>
                    window.location.href.includes("/products")
                      ? setCookie("showSearchHeader", 1)
                      : navigate("/searchPage")
                  }
                />

                {cookies.cart !== undefined ? (
                  cookies.cart.length > 0 ? (
                    <div
                      className="flex flex-col relative"
                      style={{
                        visibility:
                          cookies.cart !== undefined
                            ? cookies.cart.length > 0
                              ? "visible"
                              : "hidden"
                            : "hidden",
                      }}
                    >
                      <LiaShoppingBasketSolid
                        style={{ color: VendorData?.colorScheme }}
                        className="w-7 h-7 "
                        onClick={() => navigate("/cart")}
                      />

                      <div className="bg-red-600 text-white absolute -bottom-2 -right-2 text-xs w-5 flex flex-col items-center justify-center  h-5 p-1 rounded-full">
                        {returnTheQtyCost("Content")}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : window.location.href.includes("/orderDescription") ? (
        <>
          <div className=" bg-white items-center px-5 py-2 lg:hidden rowHolder">
            <div className="rowHolder items-center gap-3">
              {/* <img
            src={HamburgerIcon}
            className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
            onClick={() => {
              window.scrollTo(0, 0);
              setCookie("openSidebar", 1);
            }}
            /> */}
              {/* <GiHamburgerMenu style={{color:VendorData?.colorScheme}} onClick={() => {
                window.scrollTo(0, 0);
                setCookie("openSidebar", 1);
              }} className=" font-bold w-5 h-5" /> */}
              {/* <div className="columnHolder ">
                <div className="header font-bold">{VendorData?.businessName}</div>
                <div className="shortContent ">29 Catalogues</div>
              </div> */}
            </div>
            <div className="rowHolder gap-2">
              {/* <img
            src={SearchIcon}
            className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
            onClick={() =>
              window.location.href.includes("/products")
                ? setCookie("showSearchHeader", 1)
                : navigate("/searchPage")
            }
          /> */}
              {/* <IoMdSearch style={{color:VendorData?.colorScheme}} onClick={() =>
                window.location.href.includes("/products")
                  ? setCookie("showSearchHeader", 1)
                  : navigate("/searchPage")
              } className="w-7 h-7 " /> */}
              {cookies.cart !== undefined ? (
                cookies.cart.length > 0 ? (
                  <div
                    className="columnHolder relative"
                    style={{
                      visibility:
                        cookies.cart !== undefined
                          ? cookies.cart.length > 0
                            ? "visible"
                            : "hidden"
                          : "hidden",
                    }}
                  >
                    {/* <img
                      src={CartIcon}
                      className="squareIcons"
                      onClick={() => navigate("/cart")}
                    /> */}
                    <LiaShoppingBasketSolid
                      style={{ color: VendorData?.colorScheme }}
                      className="w-7 h-7 "
                      onClick={() => navigate("/cart")}
                    />

                    <div className="bg-red-600 text-white absolute -bottom-2 -right-2 text-xs w-5 flex flex-col items-center justify-center  h-5 p-1 rounded-full">
                      {returnTheQtyCost("Content")}
                    </div>
                    {/* <div className="bg-red-600 text-white absolute bottom-0 right-0 text-xs  2xl:px-[5px] xl:px-[5px] lg:px-[5px] 2xl:py-[2px] xl:py-[2px] lg:py-[2px]  p-[2px] rounded-lg">
                      {returnTheQtyCost("Content")}
                    </div> */}
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
          <div className="bg-white  shadow-xl border-b z-10 items-center ">
            <div className="max-w-[1600px] w-full px-5 md:px-10  py-[15px] 2xl:rowHolder mx-auto xl:rowHolder lg:rowHolder hidden">
              <div className="rowHolder gap-3 items-center">
                {/* <img
                src={LeftArrowIcon}
                className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() => navigate(-1)}
              /> */}
                <GoArrowLeft
                  style={{ color: VendorData?.colorScheme }}
                  onClick={() => navigate(-1)}
                  className="w-7 h-7  cursor-pointer"
                />
                {/* <div className="columnHolder">
                <div className="header font-bold">{VendorData?.businessName}</div>
                <div className="shortContent">29 Catalogues</div>
              </div> */}
              </div>

              <div className="rowHolder items-center gap-4">
                {/* <img
                src={ProfileIcon}
                className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() => navigate("/orders")}
              /> */}
                <FaUser
                  style={{ color: VendorData?.colorScheme }}
                  onClick={() => navigate("/orders")}
                  className="w-5 h-5 cursor-pointer"
                />

                {/* <img
                  src={SearchIcon}
                  className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                  
                /> */}
                <IoMdSearch
                  style={{ color: VendorData?.colorScheme }}
                  className="w-7 cursor-pointer  h-7"
                  onClick={() =>
                    window.location.href.includes("/products")
                      ? setCookie("showSearchHeader", 1)
                      : navigate("/searchPage")
                  }
                />

                {cookies.cart !== undefined ? (
                  cookies.cart.length > 0 ? (
                    <div
                      className="columnHolder relative"
                      style={{
                        visibility:
                          cookies.cart !== undefined
                            ? cookies.cart.length > 0
                              ? "visible"
                              : "hidden"
                            : "hidden",
                      }}
                    >
                      <LiaShoppingBasketSolid
                        style={{ color: VendorData?.colorScheme }}
                        className="w-7 h-7"
                        onClick={() => navigate("/cart")}
                      />

                      <div className="bg-red-600 text-white absolute -bottom-2 -right-2 text-xs w-5 flex flex-col items-center justify-center  h-5 p-1 rounded-full">
                        {returnTheQtyCost("Content")}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
      {window.location.href.includes("/productDescription") ||
      (window.location.href.includes("/products") &&
        (cookies.showSearchHeader == 0 ||
          cookies.showSearchHeader == undefined)) ||
      window.location.href.includes("/cart") ||
      window.location.href.includes("/orders") ? (
        <>
          <div className=" bg-white shadow-md items-center justify-between max-w-[1600px]  w-full px-5 md:px-10 py-[10px] 2xl:hidden xl:hidden lg:hidden flex flex-row gap-2 ">
            <div className="rowHolder justify-between items-center gap-x-[10px]">
              {/* <img
                src={HamburgerIcon}
                className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCookie("openSidebar", 1);
                }}
              /> */}
              <GiHamburgerMenu
                style={{ color: VendorData?.colorScheme }}
                className="squareIcons  2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(SidebarFunction(1));
                }}
              />

              <GoArrowLeft
                style={{ color: VendorData?.colorScheme }}
                onClick={() => {
                  navigate(-1);
                  dispatch(SidebarFunction(0));
                }}
                className="w-7 h-7  cursor-pointer"
              />
            </div>

            {/* <div className="header font-bold">
              {VendorData?.businessName}
            </div> */}
            <div className="rowHolder gap-2">
              {/* <img
                   src={SearchIcon}
                   className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                   onClick={() =>
                     window.location.href.includes("/products")
                       ? setCookie("showSearchHeader", 1)
                       : navigate("/searchPage")
                   }
                 /> */}
              <IoMdSearch
                style={{
                  visibility: window.location.href.includes("/products")
                    ? "visible"
                    : "hidden",
                  color: VendorData?.colorScheme,
                }}
                className="w-7 h-7  2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() =>
                  window.location.href.includes("/products")
                    ? setCookie("showSearchHeader", 1)
                    : navigate("/searchPage")
                }
              />

              {window.location.href.includes("/productDescription") ? (
                <div
                  className={`columnHolder relative ${
                    User.cart !== null
                      ? User.cart.length > 0 && returnTheQtyCost("Qty") != 0
                        ? "block"
                        : "hidden"
                      : "hidden"
                  }`}
                >
                  <LiaShoppingBasketSolid
                    style={{ color: VendorData?.colorScheme }}
                    className="w-7 h-7"
                    onClick={() => navigate("/cart")}
                  />

                  <div className="bg-red-600  text-white absolute -bottom-2 -right-2 text-xs w-5 flex flex-col items-center justify-center  h-5 p-1 rounded-full">
                    {returnTheQtyCost("Qty")}
                  </div>
                </div>
              ) : window.location.href.includes("/products") ? (
                User.cart !== null ? (
                  User.cart.length > 0 ? (
                    <div
                      className="columnHolder relative"
                      style={{
                        visibility:
                          User.cart !== undefined
                            ? User.cart.length > 0 &&
                              returnTheQtyCost("Qty") != 0
                              ? "visible"
                              : "hidden"
                            : "hidden",
                      }}
                    >
                      <LiaShoppingBasketSolid
                        style={{ color: VendorData?.colorScheme }}
                        className="w-7 h-7 "
                        onClick={() => navigate("/cart")}
                      />

                      <div className="bg-red-600 text-white absolute -bottom-1 -right-1 text-xs w-4 flex flex-col items-center justify-center  h-4 p-1 rounded-full">
                        {returnTheQtyCost("Qty")}
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : null}
            </div>
          </div>
          <div className="bg-white shadow-md ">
            <div className=" mx-auto max-w-[1600px]  w-full px-5 md:px-10 py-[15px] 2xl:rowHolder xl:rowHolder lg:rowHolder hidden">
              <div className="rowHolder items-center gap-2">
                {/* <img
                src={LeftArrowIcon}
                className="squareIcons 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                onClick={() => navigate(-1)}
              /> */}
                <GoArrowLeft
                  style={{ color: VendorData?.colorScheme }}
                  onClick={() => {
                    navigate(-1);
                    setCookie("showStickyHeader", 0);
                    setCookie("showStickyHeaderCart", 0);
                  }}
                  className="w-7 h-7  cursor-pointer"
                />
                {/* <div className="header font-bold">
                  {VendorData?.businessName}
                </div> */}
              </div>

              <div className="rowHolder  gap-3 items-center">
                <FaUser
                  style={{ color: VendorData?.colorScheme }}
                  className="squareIcons
                  2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                  onClick={() => navigate("/orders")}
                />

                {window.location.href.includes("/products") ? (
                  <IoMdSearch
                    style={{ color: VendorData?.colorScheme }}
                    className="w-7 h-7 2xl-hidden xl-hidden lg-hidden md-hidden sm-flex cursor-pointer"
                    onClick={() =>
                      window.location.href.includes("/products")
                        ? setCookie("showSearchHeader", 1)
                        : navigate("/searchPage")
                    }
                  />
                ) : null}
                {!window.location.href.includes("/cart") ? (
                  <div
                    className={`columnHolder relative ${
                      User.cart !== null
                        ? JSON.parse(User.cart).length > 0 &&
                          returnTheQtyCost("Qty") != 0
                          ? "block"
                          : "hidden"
                        : "hidden"
                    }`}
                  >
                    <LiaShoppingBasketSolid
                      style={{ color: VendorData?.colorScheme }}
                      className="w-7 h-7 cursor-pointer "
                      onClick={() => navigate("/cart")}
                    />
                    <div className="bg-red-600 text-white absolute -bottom-2 -right-2 text-xs w-5 flex flex-col items-center justify-center  h-5 p-1 rounded-full">
                      {returnTheQtyCost("Qty")}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : window.location.href.includes("/products") &&
        cookies.showSearchHeader == 1 ? (
        <div className=" bg-white justify-between  items-center h-[50px] py-2 px-5 gap-2 shadow-md rowHolder">
          <img
            src={CloseIcon}
            className="w-[20px] h-[20px] object-contain cursor-pointer"
            onClick={() => setCookie("showSearchHeader", 0)}
          />
          <input
            type="text"
            className="w-full h-full outline-0 shortContent"
            placeholder="Search for products"
            onChange={(e) => onSearchContentChanged(e)}
            value={searchContent}
          />
          <IoMdSearch
            style={{ color: VendorData?.colorScheme }}
            className="w-7 h-7 "
          />
        </div>
      ) : null}
      {window.location.href.includes("/cart") &&
      cookies.showStickyHeaderCart == 1 ? (
        <div className="rowHolder max-w-[1600px] w-full px-5 md:px-10 self-center bg-white py-[2px] items-center">
          <div className="shortContent text-lg">TOTAL</div>
          <div className="columnHolder items-center">
            <div className="font-bold text-black  text-lg">Review</div>
            <div className="text-base font-semibold text-black">
              {returnTheQtyCost("Qty")} Items
            </div>
          </div>
          <div className="shortContent  text-lg">
            ₹{returnTheQtyCost("Cost")}
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default Header;

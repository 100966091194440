import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Dahlialogo from "../Assets/Dahlialogo.png";
import { useNavigate, useNavigation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { IoMdSearch } from "react-icons/io";
import { MdLock } from "react-icons/md";
import axios from "axios";
import { Cart } from "../Assets/icons.js";
import FloatingFooter from "../Components/FloatingFooter.js";
import { FaUser } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { TestUserFunction } from "../Components/Redux/TestuserSlice.js";
import { PatchingCategoryData } from "../Components/Redux/CategorySlice.js";
import { PatchingProducts } from "../Components/Redux/ProductsSlice.js";
function Home() {
  const [cookies, setCookie] = useCookies(["cart", "openSidebar"]);
  useEffect(() => {
    if (cookies.cart !== undefined) {
      console.log("Qty:" + cookies.cart.length);
      let qty = 0;
      cookies.cart.forEach((e) => (qty += e.qty));
      console.log("Total Qty:" + qty);
    }
  });

  const navigate = useNavigate();
  const [VendorData, setVendorData] = useState({});

  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    console.log(domainname);
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname
        )
        .then((response) => {
          if (response.status == 200) {
            // dispatch(PatchingProducts(response.data))
            // FetchingProducts(response.data[0].id)
            console.log(response.data);
            setVendorData(response.data[0]);
            FetchingCategory(response.data[0].id);
            FetchingProducts(response.data[0].id);
            FetchingBanners(response.data[0].id);
            PatchSpecificUser(
              localStorage.getItem("mobileNumber"),
              response.data[0].id
            );
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  // useEffect(() => {
  //   window.addEventListener('reload', () => {
  //     let reloading = localStorage.getItem("mobileNumber");
  //     console.log(reloading);
  //     console.log('loading');

  //     if (reloading != null) {
  //       // PatchSpecificUser(reloading)
  //       alert(reloading)
  //     }
  //   })
  // }, [])
  const dispatch = useDispatch();
  async function PatchSpecificUser(number, _id) {
    try {
      axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/getUsers/individual/" +
            number +
            "|" +
            _id
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              dispatch(
                TestUserFunction({ type: "id", value: response.data[0].id })
              );
              dispatch(
                TestUserFunction({ type: "name", value: response.data[0].name })
              );
              dispatch(
                TestUserFunction({
                  type: "mobileNumber",
                  value: response.data[0].mobileNumber,
                })
              );
              dispatch(
                TestUserFunction({
                  type: "address",
                  value: response.data[0].address,
                })
              );
              dispatch(
                TestUserFunction({
                  type: "delivery_address",
                  value: response.data[0].delivery_address,
                })
              );
              dispatch(
                TestUserFunction({ type: "cart", value: response.data[0].cart })
              );
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {}
  }

  const [CategoryData, setCategoryData] = useState([]);
  async function FetchingCategory(_id) {
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProductCategory/vendorSpecific/" +
            _id
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              setCategoryData(response.data.filter((e) => e.status == 1));
              dispatch(
                PatchingCategoryData(response.data.filter((e) => e.status == 1))
              );
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  const [BannerData, setBannerData] = useState([]);
  async function FetchingBanners(_id) {
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/banners/specificVendorActive/" +
            _id
        )
        .then((response) => {
          if (response.status == 200) {
            setBannerData(response.data);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  const User = useSelector((e) => e.testUser.Initialuser);
  const sidebarShow = useSelector((e) => e.sidebarStatus.initialValue);
  useEffect(() => {
    window.scrollTo(window.scrollTo(0, 0));
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  async function FetchingProducts(_id) {
    try {
      await axios
        .get(
          `https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProducts/vendorSpecific/${_id}?limit=200&offset=0`
        )
        .then((response) => {
          console.log(response.data);
          if (response.status == 200) {
            if (response.data.length > 0) {
              let store = [];
              let temp = [...response.data];
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].stock_status == 1) {
                  if (
                    new Date() >= new Date(temp[i].saleApplicableFrom) &&
                    new Date() <= new Date(temp[i].saleApplicableTo)
                  ) {
                    store.push(temp[i]);
                  } else if (
                    temp[i].saleApplicableFrom == null ||
                    temp[i].saleApplicableTo == null
                  ) {
                    store.push(temp[i]);
                  }
                }
              }
              dispatch(PatchingProducts(store));
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  function BannerClickFunction(_data) {
    if (_data.purpose == "CATEGORY") {
      navigate("/products", {
        state: {
          vendordata: VendorData,
          userId: VendorData.id,
          CategoryID: _data.purposeId,
          CategoryName: CategoryData.find((e) => e.id == _data.purposeId)
            .cat_name,
        },
      });
    } else if (_data.purpose == "PRODUCT") {
      axios
        .get(
          `https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProducts/categoryAndVendorSpecific/${VendorData.id}|${_data.purposeId}?limit=200&offset=0`
        )
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);

            // navigate("/productDescription", {
            //   state: { productArray: response.data, indexValue: 0 },
            // })
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }


  return (
    <div className="relative bg-white">
      <div className="max-w-[1600px] w-full  p-5 mx-auto flex flex-col gap-5 h-screen">
        <div className="flex flex-row items-center justify-center lg:justify-between">
          <div className="lg:block hidden">
            <img
              src={
                VendorData?.businessLogoFile == null
                  ? Dahlialogo
                  : `https://storage.googleapis.com/cloudsb/vendors/${VendorData?.businessLogoFile}`
              }
              className="lg:h-[50px] h-[35px]"
            />
          </div>
          <div className="flex flex-col lg:hidden items-center">
            <img
              src={
                VendorData?.businessLogoFile == null
                  ? Dahlialogo
                  : `https://storage.googleapis.com/cloudsb/vendors/${VendorData?.businessLogoFile}`
              }
              className="lg:h-[50px] h-[35px]"
            />
            <div className="heading ">{VendorData?.businessName}</div>
            <div className="shortContent text-center">
              {CategoryData.length} Catalogues
            </div>
          </div>
          <FaUser
            style={{ color: VendorData?.colorScheme }}
            onClick={() => navigate("/orders")}
            className="w-5 h-5 lg:block hidden cursor-pointer "
          />
        </div>
        <div
          className="border cursor-pointer flex flex-row items-center justify-between p-3 shadow rounded-full"
          onClick={() =>
            navigate("/searchPage", {
              state: { userId: VendorData.id, vendordata: VendorData },
            })
          }
        >
          <div className="font-semibold text-sm text-gray-400">
            Search for products
          </div>
          <IoMdSearch className="w-5 h-5 text-[#D3D3D3]" />
        </div>
        <div className="h-[200px] w-full overflow-hidden rounded-md">
          <Slider {...settings}>
            {BannerData.length > 0 &&
              BannerData.map((e, index) => (
                <img
                  onClick={() => BannerClickFunction(e)}
                  key={index}
                  className="object-contain"
                  src={
                    "https://storage.googleapis.com/cloudsb/banners/" +
                    e.bannerUrl
                  }
                />
              ))}
          </Slider>
        </div>
        <div className="gridHolderCatalogue gap-5 relative">
          {CategoryData.length > 0 &&
            CategoryData.map((item, index) =>
              item.status == 1 ? (
                <div
                  className="catalogueParent relative cursor-pointer shadow-sm rounded-xl"
                  key={index}
                >
                  <div
                    className="catalogueSubParent"
                    onClick={() =>
                      navigate("/products", {
                        state: {
                          vendordata: VendorData,
                          userId: VendorData.id,
                          CategoryID: item.id,
                          CategoryName: item.cat_name,
                        },
                      })
                    }
                  >
                    <img
                      className="imageHeights"
                      src={
                        "https://storage.googleapis.com/cloudsb/product_category/" +
                        item.thumbnail
                      }
                    />
                    <div className="mainContent line-clamp-1 md:mt-2 mt-1 px-2 break-all">
                      {item.cat_name}
                    </div>
                    <div className="shortContent text-center">
                      Private Catalogue
                    </div>
                  </div>
                </div>
              ) : null
            )}
        </div>
      </div>
      {sidebarShow == 1 ? <Sidebar /> : null}
      {User.cart != null &&
      JSON.parse(User.cart).findIndex((e) => e.V == VendorData.id) != -1 ? (
        <FloatingFooter />
      ) : null}
    </div>
  );
}
export default Home;

import React, { useEffect, useState } from "react";
import RightArrowIcon from "../Assets/RightArrowIcon.png";
import CartIcon from "../Assets/CartIcon.png";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { LiaShoppingBasketSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import axios from "axios";
function FloatingFooter() {
  const navigate = useNavigate();
  const User = useSelector((e) => e.testUser.Initialuser);

  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [vendorId, setvendorId] = useState(null);
  const [vendorColorScheme, setVendorColorScheme] = useState(null);

  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            // dispatch(PatchingProducts(response.data))
            // FetchingProducts(response.data[0].id)
            setvendorId(response.data[0].id);
            setVendorColorScheme(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  const returnTheQtyCost = (purpose) => {
    if (User.cart != null) {
      if (purpose === "Qty") {
        let count = 0;
        JSON.parse(User.cart).forEach((element) => {
          if (element.V == vendorId) count += element.Q;
        });
        return count;
      } else if (purpose === "Cost") {
        let count = 0;
        JSON.parse(User.cart).forEach((element) => {
          if (element.V == vendorId) count += element.P * element.Q;
        });
        return count;
      }
    }
  };
  return (
    <div
      style={{ backgroundColor: vendorColorScheme?.colorScheme }}
      className={`rowHolder sticky bottom-0 w-full md:w-[500px] px-[10px] py-[2px] m-auto   items-center  cursor-pointer rounded-md`}
      onClick={() => navigate("/cart")}
    >
      <div className="rowHolder text-white items-center p-2 gap-2">
        <LiaShoppingBasketSolid className="w-7 h-7" />
        <div className="columnHolder">
          <div className=" text-left font-semibold">
            {returnTheQtyCost("Qty")} items
          </div>
          <div className=" text-left font-semibold">
            ₹{returnTheQtyCost("Cost")}
          </div>
        </div>
      </div>
      <div className="rowHolder gap-x-[5px]  items-center">
        <div className="text-white font-semibold tracking-wide">VIEW CART</div>
        <img className="squareIcons" src={RightArrowIcon} />
      </div>
    </div>
  );
}
export default FloatingFooter;

import React, { useEffect, useState } from "react";
import RightArrowIcon from "../Assets/RightArrowIcon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { UpdateTheData } from "../APi/ApiCollection";
import { TestUserFunction } from "./Redux/TestuserSlice";
import { PatchingProducts } from "./Redux/ProductsSlice";
function FloatingFooterConfirmOrder() {
  const navigate = useNavigate();
  const User = useSelector((e) => e.testUser.Initialuser);
  const Products = useSelector((e) => e.productdata.ProductsInitial);
  console.log(Products);

  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [VendorData, setVendorData] = useState({});
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            setVendorData(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  function PlaceOrderFunction() {
    if (ValidatingProductsQuantity()) {
      const data = {
        address: User.address,
        cart: JSON.stringify(
          JSON.parse(User.cart).filter((e) => e.V == VendorData.id)
        ),
        posteddate:
          new Date().getUTCFullYear() +
          "-" +
          returnThePaddedValues(new Date().getUTCMonth() + 1) +
          "-" +
          returnThePaddedValues(new Date().getUTCDate()) +
          " " +
          returnThePaddedValues(new Date().getHours()) +
          ":" +
          returnThePaddedValues(new Date().getMinutes()) +
          ":" +
          returnThePaddedValues(new Date().getSeconds()),
        user_id: User.id,
        vendorId: VendorData.id,
        Payment_status: "1",
        delivery_address: JSON.parse(User.delivery_address)[0].Position,
      };
      try {
        axios
          .post("https://cbdconsole-6zwsgvs5iq-el.a.run.app/PostOrders", data)
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data);
              let data = JSON.parse(User.cart).filter(
                (e) => e.V == VendorData.id
              );
              for (let i = 0; i < data.length; i++) {
                let product = Products.find((e) => e.product_id == data[i].I);
                PatchingFunction(
                  data[i].I,
                  product.product_Quantity - data[i].Q
                );
              }
              PatchingUsersCart();
              PatchingVendorRegistrationToken();
            }
          });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      console.log("no stock");
    }
  }

  const returnThePaddedValues = (value) => {
    let paddedValue =
      value.toString().length < 2 ? value.toString().padStart(2, 0) : value;
    return paddedValue;
  };
  async function FetchingProducts(_id) {
    try {
      await axios
        .get(
          `https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProducts/vendorSpecific/5?limit=200&offset=0`
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              let store = [];
              let temp = [...response.data];
              for (let i = 0; i < temp.length; i++) {
                if (temp[i].stock_status == 1) {
                  if (
                    new Date() >= new Date(temp[i].saleApplicableFrom) &&
                    new Date() <= new Date(temp[i].saleApplicableTo)
                  ) {
                    store.push(temp[i]);
                  } else if (
                    temp[i].saleApplicableFrom == null ||
                    temp[i].saleApplicableTo == null
                  ) {
                    store.push(temp[i]);
                  }
                }
              }
              dispatch(PatchingProducts(store));
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  function ValidatingProductsQuantity() {
    let data = JSON.parse(User.cart).filter((e) => e.V == VendorData.id);
    for (let i = 0; i < data.length; i++) {
      let CartProd = data[i];
      let product = Products.find((e) => e.product_id == CartProd.I);
      console.log(CartProd);
      console.log(product);

      if (product.product_Quantity < CartProd.Q) {
        alert(product.product_name + " is Out of Stock");
        return false;
      }
    }
    return true;
  }
  async function PatchingFunction(_id, Qty) {
    const data = {
      product_Quantity: Qty,
      updatedtime:
        new Date().getUTCFullYear() +
        "-" +
        (new Date().getUTCMonth() + 1) +
        "-" +
        new Date().getUTCDate() +
        "-" +
        new Date().getUTCHours() +
        "-" +
        new Date().getUTCMinutes() +
        "-" +
        new Date().getUTCSeconds(),
    };
    try {
      await axios
        .patch(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/PatchProducts/" + _id,
          data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  const dispatch = useDispatch();
  async function PatchingUsersCart() {
    let result = JSON.parse(User.cart).filter((e) => e.V !== VendorData.id);
    const data1 = {
      cart: result.length == 0 ? null : JSON.stringify(result),
    };
    try {
      const response = await UpdateTheData(User.id, data1);
      if (response.status == 200) {
        dispatch(
          TestUserFunction({
            type: "cart",
            value: result.length == 0 ? null : JSON.stringify(result),
          })
        );
        FetchingProducts(VendorData.id);
        alert("Successfully Ordered");
        navigate("/home");
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function PatchingVendorRegistrationToken() {
    if (localStorage.getItem("TokenExpiryTime") != null) {
      if (
        new Date().getTime() > Number(localStorage.getItem("TokenExpiryTime"))
      ) {
        try {
          await axios
            .get(
              "https://node-service-app-ifox3xnafa-el.a.run.app/getTokenForNotification"
            )
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data.expiry_date);

                localStorage.setItem("Token", response.data.access_token);
                localStorage.setItem(
                  "TokenExpiryTime",
                  response.data.expiry_date
                );
                PostNotificationtoAdmin();
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        } catch (error) {
          console.log(error.message);
        }
      }
    } else {
      try {
        await axios
          .get(
            "https://node-service-app-ifox3xnafa-el.a.run.app/getTokenForNotification"
          )
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data.expiry_date);

              localStorage.setItem("Token", response.data.access_token);
              localStorage.setItem(
                "TokenExpiryTime",
                response.data.expiry_date
              );
              PostNotificationtoAdmin();
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  console.log(localStorage.getItem("Token"));

  async function PostNotificationtoAdmin() {
    try {
      await axios
        .post(
          "https://fcm.googleapis.com/v1/projects/p4u-console/messages:send",
          {
            message: {
              token: VendorData.registrationToken,
              notification: {
                title: `You have new order ${VendorData.vendorName}`,
                body: `You have new order ${VendorData.vendorName}`,
              },
              webpush: {
                fcm_options: {
                  link: "https://iselladmin.web.app",
                },
              },
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <div
      style={{
        backgroundColor:
          User.address == null ||
          User.address == "" ||
          User.delivery_address == null
            ? VendorData?.colorScheme
            : VendorData?.colorScheme,
      }}
      className={`flex flex-row justify-between sticky bottom-0 lg:max-w-screen-md p-5 rounded-md z-30 m-auto w-full shadow-md`}
      onClick={() => {
        if (
          User.address !== null &&
          User.address != "" &&
          User.delivery_address !== null
        ) {
          PlaceOrderFunction();
        } else {
          alert("Fill the Address");
        }
      }}
    >
      <img className="squareIcons opacity-0" src={RightArrowIcon} />
      <div className="text-base text-white">CONFIRM ORDER</div>
      <img className="squareIcons" src={RightArrowIcon} />
    </div>
  );
}
export default FloatingFooterConfirmOrder;

import React, { useEffect, useState } from "react";
import Dahlialogo from "../Assets/Dahlialogo.png";
import { useSelector } from "react-redux";
import axios from "axios";
function Pdfpage({ data, returnTheQtyCost, reference,id }) {
  const User = useSelector((e) => e.testUser.Initialuser);
  useEffect(() => {
    FetchingSpecificUser();
  }, []);
  const [VendorData, setVendorData] = useState({});
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split("/")[2];
    try {
      await axios
        .get(
          "https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/" +
            domainname.trim()
        )
        .then((response) => {
          if (response.status == 200) {
            setVendorData(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  console.log( VendorData?.businessLogoFile == null
    ? Dahlialogo
    : `https://storage.googleapis.com/cloudsb/vendors/${VendorData?.businessLogoFile}`);
  return (
    <div ref={reference} id={id} className="flex-1 m-5">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex  space-x-3 flex-row items-center">
          <img
            src={
              VendorData?.businessLogoFile == null
                ? Dahlialogo
                : `https://storage.googleapis.com/cloudsb/vendors/${VendorData?.businessLogoFile}`
            }
            className="w-16 h-16 object-contain"
          />
          <div className="flex flex-col">
            <h1 className="text-black font-bold text-base uppercase">
              {VendorData?.businessName}
            </h1>
            <h1 className="text-base font-semibold opacity-80">
              {User.mobileNumber}
            </h1>
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="text-sm font-semibold text-black opacity-85">
            Order ID:DC {data.id}
          </h1>
          <h1 className="text-black font-semibold text-sm ">
            {new Date(data.posteddate).getUTCDate() + "/"}
            {new Date(data.posteddate).getUTCMonth() + 1 + "/"}
            {new Date(data.posteddate).getUTCFullYear()}
          </h1>
        </div>
      </div>
      <div className="flex w-full flex-row-reverse justify-between mt-8">
        <div className="flex flex-col">
          {/* <h1 className='text-black font-semibold text-sm uppercase'>Customer Details</h1>
          <h1 className='text-base font-medium mt-5 opacity-80'>{JSON.parse(User.address).N}</h1>
          <h1 className='text-base font-medium opacity-80'>{User.mobileNumber}</h1> */}
        </div>
        <div className="flex flex-col">
          <h1 className="text-black font-semibold text-sm uppercase">
            Bill To
          </h1>
          <div className="text-base  text-black mt-1">
            Customer Name :
            {JSON.parse(User.delivery_address)[data.delivery_address].CusName}
          </div>
          <div className="text-base  text-black mt-1">
            Mobile Number :
            {JSON.parse(User.delivery_address)[data.delivery_address].CusMobile}
          </div>
          <div className="text-base  text-black mt-1">
            Address :
            {JSON.parse(User.delivery_address)[data.delivery_address].Ad1}
            {JSON.parse(User.delivery_address)[data.delivery_address].Ad2}
          </div>
          <div className="text-base  text-black ">
            City :
            {JSON.parse(User.delivery_address)[data.delivery_address].CusCity},
            {JSON.parse(User.delivery_address)[data.delivery_address].CusState}
          </div>
          <div className="text-base  text-black ">
            Pincode :
            {JSON.parse(User.delivery_address)[data.delivery_address].pincode}
          </div>
        </div>
      </div>

      <div className="w-full mt-5 overflow-auto ">
        <table className="w-full ">
          <colgroup>
            <col width={"10%"} />
            <col width={"10%"} />
            <col width={"40%"} />
            <col width={"20%"} />
            <col width={"10%"} />
            <col width={"10%"} />
          </colgroup>
          <thead>
            <th>No.</th>
            <th>Product</th>
            <th>Item</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Total</th>
          </thead>
          <tbody>
            {JSON.parse(data.cart).map((e, index) => (
              <tr className="h-[60px] flex-1 border-b border-b-black rounded">
                <td>{index + 1}</td>
                <td className="  border-none w-full flex flex-col items-center pt-4  justify-center h-full">
                  <img
                    className="w-[60px] h-full rounded-md self-center object-contain"
                    src={
                      "https://storage.googleapis.com/cloudsb/ProductImage/" +
                      e.T
                    }
                  />
                </td>
                <td>{e.N}</td>
                <td>{e.Q}</td>
                <td>₹ {e.P}</td>
                <td>₹ {e.P}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row items-center mt-5 justify-between">
        <div></div>
        <div className="flex flex-col justify-between">
          <div className="bg-gray-100  mt-5 p-6">
            <h1 className="text-base font-medium  opacity-80 uppercase">
              TOTAL
            </h1>
            <h1 className="text-lg  font-semibold text-black">
              ₹ {returnTheQtyCost("Cost", JSON.parse(data.cart))}
            </h1>
            <h1 className="text-sm font-normal text-black opacity-85">
              {returnTheQtyCost("Qty", JSON.parse(data.cart))} products
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pdfpage;
